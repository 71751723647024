<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/samples" class="text-green hover:underline">Sample Requests</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">{{ sample.number }}</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingSample">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="col-span-3 shadow mb-5 px-5 py-2 text-sm bg-yellow-500 text-white font-bold" v-if="sample.archived_at">
          This sample request is archived.
        </div>
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <div class="text-xs flex text-gray-400">
            <span >Created {{ parseDate(sample.created_at) }}</span>
            <span class="text-right flex-grow">Updated {{ parseDate(sample.updated_at) }}</span>
          </div>
          <span class="block text-gray-400 mt-10">Sample Request</span>
          <h2 class="font-bold text-3xl">{{ sample.number }}</h2>
          <span class="block mt-10 font-bold">Shipment Status: {{ sample.shipping_status_text }}</span>
          <span class="block mt-1 font-bold">Sales Status: {{ sample.sales_status_text }}</span>
          <span class="block text-sm"><a href="#" class="text-green hover:underline" @click="showChangeStatusModal = true">Update Status</a></span>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="text-sm">
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Name</span>
              <span class="w-9/12 font-bold">{{ sample.name }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Company</span>
              <span class="w-9/12 font-bold">{{ sample.company }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Address</span>
              <p class="w-9/12">
                <span class="block font-bold">{{ sample.address }}</span>
                <span class="block" v-if="sample.address_two">{{ sample.address_two }}</span>
                <span class="block">{{ sample.city }}, {{ sample.state }} {{ sample.zip }}</span>
                <span class="block">{{ sample.country }}</span>
              </p>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Carrier</span>
              <span class="w-9/12 font-bold">{{ sample.carrier }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Potential Value</span>
              <span class="w-9/12 font-bold" v-if="sample.potential_value">${{ Number(sample.potential_value).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Notes</span>
              <span class="w-9/12">{{ sample.notes }}</span>
            </div>
          </div>
        </div>
        <div class="shadow bg-gray-100 mt-5 col-span-3">
          <div class="px-5 py-2" :class="{'bg-gray-50':key % 2}" v-for="(part, key) in sample.parts" v-bind:key="part.id">
            <div class="flex">
              <span class="w-6/12">
                <span class="font-bold">{{ part.part_number }}</span> x {{ part.quantity }}
                <span class="text-sm text-gray-400" v-if="part.annual_usage">
                  (Annual Usage: {{ part.annual_usage }})
                </span>
              </span>
              <span class="text-gray-400 text-sm">{{ part.comments }}</span>
              <span class="text-right flex-grow text-sm"><span v-if="part.sent_at">Sent {{ part.number_sent }} pieces on {{ parseDate(part.sent_at) }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3 border-t border-gray-200 mt-5">
        <div class="shadow mt-5 bg-gray-100">
          <div class="px-5 py-2 bg-gray-50 border-b border-gray-200 flex w-full">
            <h2 class="font-bold">Comments</h2>
            <span class="text-sm flex-grow text-right mt-1"><a class="text-green hover:underline cursor-pointer" @click="showAddCommentModal = true">Add Comment</a></span>
          </div>
          <div class="px-2 pb-2" v-if="sample.comments.length > 0">
            <div class="mt-2 px-3 py-2 bg-gray-50 rounded border border-gray-200" v-for="comment in sample.comments" v-bind:key="comment.id">
              <div class="text-sm flex">
                <span class="inline-block font-bold">{{ comment.owner.first_name }} {{ comment.owner.last_name }}</span>
                <span class="inline-block ml-1 text-gray-400 text-xs flex-grow text-right">{{ parseDate(comment.created_at) }}</span>
              </div>
              <p class="mt-2 text-sm">{{comment.message}}</p>
            </div>
          </div>
          <div class="px-5 py-2 italic text-gray-400 text-sm" v-if="sample.comments.length == 0">
            No comments yet
          </div>
        </div>
      </div>
    </div>

    <vue-final-modal v-model="showChangeStatusModal" classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full w-2/6 mx-4 border dark:border-gray-800 rounded bg-gray-100 dark:bg-gray-900">
      <h3 class="font-bold px-4 py-2 bg-gray-50">Update Sales Status</h3>
      <div class="px-4 py-2">
        <select class="p-2 rounded shadow w-full" v-model="sales_status">
          <option value="0">Sampled</option>
          <option value="1">Quoted</option>
          <option value="2">Pricing Approved</option>
          <option value="3">Pending</option>
          <option value="4">Won</option>
          <option value="5">Lost</option>
        </select>
      </div>
      <div class="px-4 py-2 bg-gray-50 text-right">
        <a class="text-sm mx-5 text-green hover:underline cursor-pointer" @click="showChangeStatusModal = false">Cancel</a>
        <button class="rounded bg-green text-white px-3 py-1 text-sm font-bold" @click="saveSalesStatus()">Change Status</button>
      </div>
    </vue-final-modal>

    <vue-final-modal v-model="showAddCommentModal" classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full w-2/6 mx-4 border dark:border-gray-800 rounded bg-gray-100 dark:bg-gray-900">
      <h3 class="font-bold px-4 py-2 bg-gray-50">Add Comment</h3>
      <div class="px-4 py-2">
        <textarea class="mt-2 w-full px-2 py-1 shadow" v-model="comment" ref="mes" rows="4"></textarea>
      </div>
      <div class="px-4 py-2 bg-gray-50 text-right">
        <a class="text-sm mx-5 text-green hover:underline cursor-pointer" @click="showAddCommentModal = false">Cancel</a>
        <button class="rounded bg-green text-white px-3 py-1 text-sm font-bold" @click="addComment()">Add Comment</button>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: () => {
    return {
      id: null,
      showChangeStatusModal: false,
      showAddCommentModal: false,
      comment: "",
      loadingSample: true,
      sales_status: null,
    }
  },
  computed: {
    ...mapGetters({
        sample: 'samples/details'
      }),
  },

  created: function () {
    this.loadSample();
  },

  methods: {
    parseDate(date) {
			let d = new Date(date);
			return d.toLocaleDateString();
		},

    async loadSample() {
      this.loadingSample = true;
      await this.$store.dispatch('samples/loadDetails', this.$route.params.id);
      document.title = 'MLE Rep Center | Sample '+ this.sample.number;
      this.sales_status = this.sample.sales_status;
      this.loadingSample = false;
    },

    async addComment() {
      this.showAddCommentModal = false;
      await this.$store.dispatch('samples/addComment', this.comment);
      this.comment = '';
    },

    async saveSalesStatus() {
      this.showChangeStatusModal = false;
      await this.$store.dispatch('samples/updateSalesStatus', this.sales_status);
      this.sales_status = this.sample.sales_status;
    }
  }
}
</script>